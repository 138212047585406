<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import { utils } from '@/composables/utils'
import PreviewImageComponent from '@/components/pages/index/PreviewImageComponent.vue'

interface Props {
  images: string[]
  previewImage?: string
  scaleHeight?: number
  scaleWidth?: number
  intervalSec?: number
  isTopElement?: boolean
  isEndElement?: boolean
  translateY?: number
}
const props = defineProps<Props>()

const { windowHeight } = utils
const parallaxWrapper = ref(null)
const { bottom, height } = useElementBounding(parallaxWrapper)

const parallaxScaledSizeHeight = computed(() => Math.ceil(height.value * (props.scaleHeight ?? 1.5)))
const parallaxOffsetMax = computed(() => parallaxScaledSizeHeight.value - height.value - 10)
const calcViewportSize = computed(() => windowHeight.value + height.value)
const parallaxScaledSizeWidth = computed(() => Math.ceil(100 * (props.scaleWidth ?? 1.02)))

const offset = computed(() => {
  let calcPosition
  if (bottom.value <= 0) {
    calcPosition = 0
  } else if (bottom.value >= calcViewportSize.value) {
    calcPosition = calcViewportSize.value
  } else {
    calcPosition = bottom.value
  }
  if (props.isTopElement) {
    return Math.ceil(parallaxOffsetMax.value * (calcPosition / (height.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
  }
  if (props.isEndElement) {
    return Math.ceil(parallaxOffsetMax.value * ((calcPosition - windowHeight.value) / (height.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
  }

  return Math.ceil(parallaxOffsetMax.value * (calcPosition / (calcViewportSize.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
})
const indexImage = ref(0)
function getOffsetIndex(offset: number) {
  if (offset) {
    return indexImage.value + offset >= props.images.length ? 0 : indexImage.value + offset
  }
  return indexImage.value + offset < props.images.length ? props.images.length - 1 : indexImage.value + offset
}
const nextImage = computed(() => {
  return getOffsetIndex(1)
})
const prevImage = computed(() => {
  return getOffsetIndex(-1)
})
const loadingImages = ref<Record<number, boolean>>({})
// const loadingImgPreview = ref(true)
const hideImgPreview = ref(false)

setInterval(
  () => {
    const nextIndex = getOffsetIndex(1)
    if (loadingImages.value?.[indexImage.value] === false && loadingImages.value?.[nextIndex] === false) {
      indexImage.value = nextIndex
    }
  },
  props.intervalSec || 5 * 1000,
)

function onLoadImg(index: number) {
  loadingImages.value[index] = false
  if (index) {
    return
  }
  if (!props.previewImage) {
    hideImgPreview.value = true
    return
  }
  setTimeout(() => {
    hideImgPreview.value = true
  }, 4000)
}
</script>

<template>
  <div
    ref="parallaxWrapper"
    class="parallax_wrapper overflow-hidden w-full h-full relative flex bg-white"
    :class="{}"
    :style="{ '--parallaxOffset': `${offset}px`, '--parallaxScaledSizeHeight': `${parallaxScaledSizeHeight}px`, '--parallaxScaledSizeWidth': `${parallaxScaledSizeWidth}%` }"
  >
    <PreviewImageComponent
      :hide-img-preview="hideImgPreview"
      :loading="loadingImages[0] !== false"
      :preview-image="previewImage"
    />
    <div
      v-for="(path, index) in images"
      :key="index"
      class="parallax absolute"
      :class="{
        next: index === nextImage,
        active: index === indexImage,
        prev: index === prevImage,
      }"
    >
      <img
        :src="path"
        class="lazy-img absolute inset-0 bg-cover object-center object-cover w-full h-full"
        :class="{
          loading: loadingImages[index] !== false,
        }"
        loading="lazy"
        alt="slide"
        @load="onLoadImg(index)"
      >
    </div>
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.parallax_wrapper {
  --parallaxScaledSizeHeight: 100%;
  --parallaxScaledSizeWidth: 100%;
  --parallaxOffset: 0px;
  min-height: inherit;
  :deep(.parallax) {
    will-change: transform;
    width: calc(var(--parallaxScaledSizeWidth));
    height: calc(var(--parallaxScaledSizeHeight));
    transform: translateY(var(--parallaxOffset));
    transition-property: transform;
    transition-timing-function: linear;
    transition-duration: 5ms;
    display: none;
    z-index: 0 !important;
    opacity: 0;
    &.active {
      display: block;
      z-index: 1 !important;
      opacity: 1;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 1s;
    }
    &.prev {
      display: block;
      z-index: 1 !important;
      opacity: 0;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 1s;
    }
    &.next {
      display: block;
    }
    .lazy-img {
      &.loading {
        opacity: 0;
      }
      &:not(.loading) {
        animation:
          bluring-in 2s linear,
          opacity-in 2s linear;
      }
    }
  }
}
</style>
