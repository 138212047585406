import './css/index.css'
import { createApp } from 'vue'
import { Icon as IconIfy } from '@iconify/vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import routerInitPlugin from '@/router/routerInitPlugin'

const head = createHead()

const app = createApp(App)

app.use(routerInitPlugin)
app.use(head)
app.component('IconIfy', IconIfy)

app.mount('#app')
