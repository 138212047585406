<script setup lang="ts">
import Flicking from '@egjs/vue3-flicking'
import '@egjs/vue3-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/arrow.css'
import { Arrow, AutoPlay } from '@egjs/flicking-plugins'

import CarouseSlideImg1 from '@/assets/images/pages/index/sections/games/slider_QL.webp'
import CarouseSlideImg2 from '@/assets/images/pages/index/sections/games/slider_HL.webp'
import CarouseSlideImg3 from '@/assets/images/pages/index/sections/games/slider_SnR.webp'
import CarouseSlideImg4 from '@/assets/images/pages/index/sections/games/slider_TG.webp'
import CarouseSlideImg5 from '@/assets/images/pages/index/sections/games/slider_FW.webp'

import CarouseSlideImg1Preview from '@/assets/images/pages/index/sections/games/slider_QL_blur.webp'
import CarouseSlideImg2Preview from '@/assets/images/pages/index/sections/games/slider_HL_blur.webp'
import CarouseSlideImg3Preview from '@/assets/images/pages/index/sections/games/slider_SnR_blur.webp'
import CarouseSlideImg4Preview from '@/assets/images/pages/index/sections/games/slider_TG_blur.webp'
import CarouseSlideImg5Preview from '@/assets/images/pages/index/sections/games/slider_FW_blur.webp'

import QlLogo from '@/assets/images/pages/games/ql/logo_QL.webp'
import SnRLogo from '@/assets/images/pages/games/snr/logo_SnR.webp'
import HlLogo from '@/assets/images/pages/games//hl/logo_HL.webp'
import GangLogo from '@/assets/images/pages/games//gang/logo_TG.webp'
import FwLogo from '@/assets/images/pages/games//fw/FW_logo.webp'

const { screens } = utils
const plugins = [new Arrow({ moveCount: 1 }), new AutoPlay({ duration: 3000, direction: 'NEXT', stopOnHover: true })]

const numbersOfClones = 1

const slides = [
  {
    path: CarouseSlideImg1,
    preview: CarouseSlideImg1Preview,
    alt: 'Questland',
    class: 'home-game-slide home-game-slide-img transition-all',
    route: { path: '/games/questland' },
    logo: QlLogo,
  },
  {
    path: CarouseSlideImg2,
    preview: CarouseSlideImg2Preview,
    alt: 'Hero Legacy',
    class: 'home-game-slide home-game-slide-img transition-all',
    route: { path: '/games/hero-legacy' },
    logo: HlLogo,
  },
  {
    path: CarouseSlideImg3,
    preview: CarouseSlideImg3Preview,
    alt: 'Slash&Roll',
    class: 'home-game-slide home-game-slide-img transition-all',
    route: { path: '/games/slash-and-roll' },
    logo: SnRLogo,
  },
  {
    path: CarouseSlideImg4,
    preview: CarouseSlideImg4Preview,
    alt: 'The Gang',
    class: 'home-game-slide home-game-slide-img transition-all',
    route: { path: '/games/the-gang' },
    logo: GangLogo,
  },
  {
    path: CarouseSlideImg5,
    preview: CarouseSlideImg5Preview,
    alt: 'Fable Wars',
    class: 'home-game-slide home-game-slide-img transition-all',
    route: { path: '/games/fable-wars' },
    logo: FwLogo,
  },
]
</script>

<template>
  <section
    id="games"
    class="section"
  >
    <Flicking
      class="flick-carousel"
      :options="{ circular: true, align: screens.big.alLeast ? 'prev' : 'center' }"
      :plugins="plugins"
    >
      <template
        v-for="n in numbersOfClones + 1"
        :key="n"
      >
        <div
          v-for="(slide, index) in slides"
          :key="`slide_${n}_${index}`"
          class="flick-carousel-slide relative"
          :class="[slide.class]"
        >
          <lazy-image-component
            class="slide-image"
            :src="slide.path"
            :preview-src="slide.preview"
            :alt="slide.alt"
            disable-lazy
          />
          <div class="absolute bottom-10 left-0 right-0 z-10 text-center transition-all bg-transparent">
            <lazy-image-component
              v-if="slide?.logo"
              class="inline-block w-4/5 big:w-1/2"
              :src="slide.logo"
              :alt="`${slide.alt} logo`"
            />
          </div>
          <router-link
            :to="slide.route.path"
            class="absolute top-0 left-0 right-0 bottom-0 bg-transparent z-10"
          />
        </div>
      </template>

      <template #viewport>
        <span class="flicking-arrow-prev" />
        <span class="flicking-arrow-next" />
      </template>
    </Flicking>
  </section>
</template>

<style scoped lang="postcss">
/*look also to src/css/flickCarousel.css*/
.home-game-slide {
  background-color: #0a0a0a;
  color: white;
  &-img {
    width: 80%;
    height: auto;
    &:hover {
      .slide-image img {
        transform: translate(-50%, -50%) scale(1.2);
        z-index: 1;
      }
    }
  }
}
@media (min-width: theme('screens.big')) {
  .home-game-slide {
    &-img {
      width: 25%;
      height: auto;
    }
  }
}
</style>
