<script setup lang="ts">
interface Props {
  src: string
  previewSrc?: string
  alt?: string
  disableLazy?: boolean
  previewIconSize?: number
  bgColor?: string
}
const props = defineProps<Props>()
const loading = ref(true)
const hideImgPreview = ref(false)
function onLoadImg() {
  loading.value = false
  if (!props.previewSrc) {
    hideImgPreview.value = true
    return
  }
  setTimeout(() => {
    hideImgPreview.value = true
  }, 4000)
}
</script>

<template>
  <div class="relative h-full w-full">
    <preview-image-component
      :hide-img-preview="hideImgPreview"
      :loading="loading !== false"
      :preview-image="previewSrc"
      :size="previewIconSize"
      :bg-color="bgColor"
    />
    <img
      class="lazy-img relative bg-cover object-center object-cover w-full h-full"
      :src="src"
      :alt="alt"
      :loading="disableLazy ? undefined : 'lazy'"
      @load="onLoadImg"
    >
  </div>
</template>

<style scoped lang="postcss">
div {
  :deep(img) {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
  }

  &.loading {
    filter: blur(10px);
    animation: pulsating-opacity 6s linear infinite;
    background-color: #d8d8da;
    :deep(img) {
      opacity: 0;
    }
  }
}
</style>
