<script setup lang="ts">
interface Props {
  previewImage: string | undefined
  hideImgPreview: boolean
  loading: boolean
  size?: number
  bgColor?: string
}
defineProps<Props>()

const loadingImgPreview = ref(true)
</script>

<template>
  <template v-if="!hideImgPreview">
    <div
      v-if="loadingImgPreview"
      class="absolute inset-0 preview-image-base64"
      :style="[`--size: ${size ?? 20}%;}`, { backgroundColor: bgColor }]"
    />
    <img
      v-if="!!previewImage"
      :src="previewImage"
      class="absolute inset-0 bg-cover object-center object-cover w-full h-full preview-image"
      :class="{ loading }"
      loading="lazy"
      alt="preview image"
      @load="loadingImgPreview = false"
    >
  </template>
</template>

<style scoped lang="postcss">
.preview-image-base64 {
  --size: 20%;
  opacity: 0;
  background-size: 0;
  background-position: center 40%;
  background-repeat: no-repeat;
  background-color: #d8d8da;
  animation:
    opacity-puls-in 3s linear infinite,
    opacity-in 1s forwards,
    preview-image 300ms ease-in-out 2s forwards;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='white' d='M216 40H40a16 16 0 0 0-16 16v144a16 16 0 0 0 16 16h176a16 16 0 0 0 16-16V56a16 16 0 0 0-16-16m0 16v102.75l-26.07-26.06a16 16 0 0 0-22.63 0l-20 20l-44-44a16 16 0 0 0-22.62 0L40 149.37V56ZM40 172l52-52l80 80H40Zm176 28h-21.37l-36-36l20-20L216 181.38zm-72-100a12 12 0 1 1 12 12a12 12 0 0 1-12-12'/%3E%3C/svg%3E");
}
.preview-image {
  z-index: 0;
  &.loading {
    transform: scale(1.05);
    opacity: 0;
    animation:
      scaling 3s linear infinite,
      opacity-in 2s ease-out forwards;
  }
}
@keyframes opacity-puls-in {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes preview-image {
  100% {
    background-size: var(--size);
  }
}
@keyframes scaling {
  0% {
    transform: scale(1.05);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.05);
  }
}
</style>
