<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import { utils } from '@/composables/utils'

// TODO default values for props; implement speed; scale only for main bg; multiimages; slot for content;
interface Props {
  images: { url: string, alt: string, speed?: number }[]
  previewImage?: string
  scaleHeight?: number
  scaleWidth?: number
  isTopElement?: boolean
  isEndElement?: boolean
  translateY?: number
}
const props = defineProps<Props>()

const { windowHeight } = utils
const parallaxWrapper = ref(null)
const { bottom, height } = useElementBounding(parallaxWrapper)

const parallaxScaledSizeHeight = computed(() => Math.ceil(height.value * (props.scaleHeight ?? 1.5)))
const parallaxOffsetMax = computed(() => parallaxScaledSizeHeight.value - height.value - 10)
const calcViewportSize = computed(() => windowHeight.value + height.value)
const parallaxScaledSizeWidth = computed(() => Math.ceil(100 * (props.scaleWidth ?? 1.02)))

const offset = computed(() => {
  let calcPosition
  if (bottom.value <= 0) {
    calcPosition = 0
  } else if (bottom.value >= calcViewportSize.value) {
    calcPosition = calcViewportSize.value
  } else {
    calcPosition = bottom.value
  }
  if (props.isTopElement) {
    return Math.ceil(parallaxOffsetMax.value * (calcPosition / (height.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
  }
  if (props.isEndElement) {
    return Math.ceil(parallaxOffsetMax.value * ((calcPosition - windowHeight.value) / (height.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
  }

  return Math.ceil(parallaxOffsetMax.value * (calcPosition / (calcViewportSize.value || 1)) - parallaxOffsetMax.value) + (props.translateY ?? 0)
})
const loadingImages = ref<Record<number, boolean>>({})
const hideImgPreview = ref(false)
function onLoadImg(index: number) {
  loadingImages.value[index] = false
  if (index) {
    return
  }
  if (!props.previewImage) {
    hideImgPreview.value = true
    return
  }
  setTimeout(() => {
    hideImgPreview.value = true
  }, 4000)
}
</script>

<template>
  <div
    ref="parallaxWrapper"
    class="parallax_wrapper overflow-hidden w-full h-full relative flex"
    :style="[{ '--parallaxOffset': `${offset}px`, '--parallaxScaledSizeHeight': `${parallaxScaledSizeHeight}px`, '--parallaxScaledSizeWidth': `${parallaxScaledSizeWidth}%` }]"
  >
    <preview-image-component
      :hide-img-preview="hideImgPreview"
      :loading="loadingImages[0] !== false"
      :preview-image="previewImage"
    />
    <div
      v-for="(image, index) in images"
      :key="index"
      class="parallax absolute"
      :class="{
        loading: previewImage && loadingImages[index] !== false,
      }"
    >
      <img
        :src="image.url"
        class="lazy-img bg-cover object-center object-cover w-full h-full"
        :alt="image.alt"
        loading="lazy"
        @load="onLoadImg(index)"
      >
    </div>
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.parallax_wrapper {
  --parallaxScaledSizeHeight: 100%;
  --parallaxScaledSizeWidth: 100%;
  --parallaxOffset: 0px;
  min-height: inherit;
  :deep(.parallax) {
    will-change: transform;
    width: calc(var(--parallaxScaledSizeWidth));
    height: calc(var(--parallaxScaledSizeHeight));
    transform: translateY(var(--parallaxOffset));
    transition-property: transform;
    transition-timing-function: linear;
    transition-duration: 5ms;
    img {
      opacity: 1;
      transition: opacity 2s ease-in-out;
    }
    &.loading {
      .lazy-img {
        opacity: 0;
      }
    }
    &:not(.loading) {
      animation:
          bluring-in 2s linear,
          opacity-in 2s linear;
    }
  }
}
</style>
